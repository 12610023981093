import {
	Modal,
	ModalHeader,
	ModalBody,
	Row,
	Col,
	Card,
	CardBody,
	CardFooter,
	Input,
} from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
	BsFillCheckCircleFill,
	BsCheckSquareFill,
	BsSquare,
} from 'react-icons/bs';
import { useSelector } from 'react-redux';
import _fetch from '../../_fetch';
import Loader from '../../components/Loader/loader';
import './style.css';
import { toast } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import AddressForm from './AddressForm';

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function CreateSubscription() {
	const history = useHistory();
	const { id } = useParams();
	const { stripeAddress, isFreeTrialAvailable } = useSelector(
		(state) => state.user
	);
	let products = [];
	const allProducts = [
		{
			name: 'BASIC PACKAGE',
			classes: '10',
			price: '60',
			discount: '25',
			features: [
				{
					type: 'text',
					text: 'Free Trial for 3 Days (If you are an eligible member)',
				},
				{
					type: 'text',
					text: '15 new workout videos every month',
				},
				{
					type: 'text',
					text: 'Access to all workout categories',
				},
				{
					type: 'text',
					text: 'Flexibility to choose any workout from a library of over 500 videos',
				},
				{
					type: 'text',
					text: 'Unlimited play time of repeat watch',
				},
				{
					type: 'text',
					text: 'New workout videos added every month',
				},
			],
			priceId: process.env.REACT_APP_STRIPE_BASIC_MONTHLY_PRICE_KEY,
			interval: 'month',
		},
		{
			name: 'STANDARD PACKAGE',
			classes: '15',
			price: '90',
			discount: '25',
			features: [
				{
					type: 'text',
					text: 'Free Trial for 3 Days (If you are an eligible member)',
				},
				{
					type: 'text',
					text: '15 new workout videos every month',
				},
				{
					type: 'text',
					text: 'Access to all workout categories',
				},
				{
					type: 'text',
					text: 'Flexibility to choose any workout from a library of over 500 videos',
				},
				{
					type: 'text',
					text: 'Unlimited play time of repeat watch',
				},
				{
					type: 'text',
					text: 'New workout videos added every month',
				},
			],
			priceId: process.env.REACT_APP_STRIPE_STANDARD_MONTHLY_PRICE_KEY,
			interval: 'month',
		},
		{
			name: 'PREMIUM PACKAGE',
			classes: '20',
			price: '120',
			discount: '25',
			features: [
				{
					type: 'text',
					text: '20 new workout videos every month',
				},
				{
					type: 'text',
					text: 'A dedicated coach assigned to you',
				},
				{
					type: 'text',
					text: 'Access to all workout categories',
				},
				{
					type: 'text',
					text: 'Flexibility to choose any workout from a library of over 500 videos',
				},
				{
					type: 'text',
					text: 'Unlimited play time of repeat watch',
				},
				{
					type: 'text',
					text: 'New workout videos added every month',
				},
			],
			priceId: process.env.REACT_APP_STRIPE_PREMIUM_MONTHLY_PRICE_KEY,
			interval: 'month',
			freeTrialMessage: null,
		},
        {
			name: 'BASIC PACKAGE',
			classes: '30',
			price: '165',
			discount: '25',
			features: [
				{
					type: 'text',
					text: '30 new workout videos every quarter',
				},
				{
					type: 'text',
					text: 'Access to all workout categories',
				},
				{
					type: 'text',
					text: 'Flexibility to choose any workout from a library of over 500 videos',
				},
				{
					type: 'text',
					text: 'Unlimited play time of repeat watch',
				},
				{
					type: 'text',
					text: 'New workout videos added every month',
				},
			],
			priceId: process.env.REACT_APP_STRIPE_BASIC_QUARTERLY_PRICE_KEY,
			interval: 'quarter',
			freeTrialMessage: null,
		},
		{
			name: 'STANDARD PACKAGE',
			classes: '45',
			price: '250',
			discount: '25',
			features: [
				{
					type: 'text',
					text: '45 new workout videos every quarter',
				},
				{
					type: 'text',
					text: 'Access to all workout categories',
				},
				{
					type: 'text',
					text: 'Flexibility to choose any workout from a library of over 500 videos',
				},
				{
					type: 'text',
					text: 'Unlimited play time of repeat watch',
				},
				{
					type: 'text',
					text: 'New workout videos added every month',
				},
			],
			priceId: process.env.REACT_APP_STRIPE_STANDARD_QUARTERLY_PRICE_KEY,
			interval: 'quarter',
			freeTrialMessage: null,
		},
		{
			name: 'PREMIUM PACKAGE',
			classes: '60',
			price: '320',
			discount: '25',
			features: [
				{
					type: 'text',
					text: '60 new workout videos every quarter',
				},
				{
					type: 'text',
					text: 'Access to all workout categories',
				},
				{
					type: 'text',
					text: 'Flexibility to choose any workout from a library of over 500 videos',
				},
				{
					type: 'text',
					text: 'Unlimited play time of repeat watch',
				},
				{
					type: 'text',
					text: 'New workout videos added every month',
				},
			],
			priceId: process.env.REACT_APP_STRIPE_PREMIUM_QUARTERLY_PRICE_KEY,
			interval: 'quarter',
			freeTrialMessage: null,
		},
	];

	products = allProducts;

	const initialPackageData = {
		name: '',
		priceId: '',
	};

	const [modalVisible, setModalVisible] = useState(false);
	// 0 for Address, 1 for Payment
	const [elementType, setElementType] = useState(0);
	const [clientSecret, setClientSecret] = useState(null);
	const [selectedPackage, setSelectedPackage] = useState(initialPackageData);
	const [loading, setLoading] = useState(false);
	const [coupon, setCoupon] = useState('');
	const [amount, setAmount] = useState({});

	const createStripeSubscription = async (isComingFromAddressForm = false) => {
		try {
			if (!isComingFromAddressForm) setLoading(true);
			if (!selectedPackage || !selectedPackage.priceId)
				return toast.error('Please select a package!');
			let url = `${process.env.REACT_APP_API_V2}/stripe-payment/subscription`;
			let res = await _fetch(url, {
				method: 'POST',
				body: {
					price_id: selectedPackage.priceId,
					coupon_code: coupon,
				},
			});
			if (res.success) {
				if (res.response?.clientSecret) {
					setClientSecret(res.response.clientSecret);
					setAmount({
						net_amount: res.response.net_amount,
						gross_amount: res.response.gross_amount,
					});
					setModalVisible(true);
					setElementType(1);
				} else {
					toast.success(
						'Free Trial started successfully, please check your email for download instructions!'
					);
					history.push('/subscription/success');
				}
			} else {
				toast.error(res.response);
			}
		} catch (err) {
			console.log(err);
			toast.error('Something went wrong!');
		} finally {
			if (!isComingFromAddressForm) setLoading(false);
		}
	};

	const createStripeUser = async (address) => {
		try {
			setLoading(true);
			if (!address) return toast.error('Please enter your address!');
			let url = `${process.env.REACT_APP_API_V2}/stripe-payment/customer`;
			let res = await _fetch(url, {
				method: 'POST',
				body: {
					address: address,
				},
			});
			if (res.success) {
				await createStripeSubscription(true);
			} else {
				toast.error(res.response);
			}
		} catch (err) {
			console.log(err);
			toast.error('Something went wrong!');
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!id) {
			history.replace('/login');
		}
	}, [id, history]);

	if (loading && !modalVisible)
		return (
			<>
				<div
					className='d-flex justify-content-center align-items-center'
					style={{
						height: '100vh',
						width: '100vw',
					}}
				>
					<Loader />
				</div>
			</>
		);
	return (
		<div className='subscription-page'>
			<div
				className='w-100 d-flex flex-column p-4 align-items-center create-subscription-content-container'
				style={{
					minHeight: '100vh',
				}}
			>
				<div
					style={{
						width: '100%',
						maxWidth: '300px',
						height: '100px',
						padding: '10px',
					}}
					className='d-flex justify-content-center align-items-center'
				>
					<img
						src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Core+Sculpt+Logo+Final.png`}
						alt=''
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'contain',
							cursor: 'pointer',
						}}
						onClick={() => {
							history.push('/');
						}}
					/>
				</div>
				<Row className='mt-4 w-100' xs='1' md='2'>
					<Col className='p-2 subscription-features-container'>
						<h1
							style={{
								fontSize: '2rem',
								fontWeight: 'bold',
							}}
						>
							CoreSculpt App Packages
						</h1>
						<p
							style={{
								whiteSpace: 'pre-line',
							}}
						>
							{`CoreSculpt App brings you a plethora of options with Circuit training, Bodyweight strength, Yoga, Pilates, Callisthenics, Resistance & strength training along with nutrition and habit coaching services into one comprehensive platform. Not easy to find such a synergy and an unmatched fusion that transforms your fitness journey into an inspiring adventure!\n\nCoreSculpt introduces you to the first ever library of over 500 follow-along videos by a single trainer! Say goodbye to mere instructions, sample videos and boring schedules, rather watch me, your fitness buddy, sweating it out alongside you, providing you unparalleled motivation in every class.\n\nRewards points, consistency badges, activity streak calendar, client inspirations, healthy eating blogs, my tips and tricks, and that community support – there is lots of fun features to let you empower your fitness journey like never before! CoreSculpt isn’t just another Fitness App, it is a culmination of years of dedication, passion, expertise, experience & knowledge and is an ever-evolving network of women.\n\nIn addition to the monthly subscription packages, you can add on the following features:`}
						</p>
						<a
							className='my-2 d-flex flex-row align-items-center'
							href='/packages'
							style={{
								cursor: 'pointer',
							}}
						>
							<BsFillCheckCircleFill
								className='text-lg mr-3 flex-shrink-0'
								style={{
									color: '#c58bf2',
								}}
							/>
							<p className='subscription-features-bullet-point '>
								Sign up to a dedicated Habit coach
							</p>
						</a>
						<a
							className='my-2 d-flex flex-row align-items-center'
							href='/packages'
							style={{
								cursor: 'pointer',
							}}
						>
							<BsFillCheckCircleFill
								className='text-lg mr-3 flex-shrink-0'
								style={{
									color: '#c58bf2',
								}}
							/>
							<p className='subscription-features-bullet-point '>
								Customised Fitness & nutrition 1 hour call with Prapti
							</p>
						</a>
						<a
							className='my-2 d-flex flex-row align-items-center'
							href='/packages'
							style={{
								cursor: 'pointer',
							}}
						>
							<BsFillCheckCircleFill
								className='text-lg mr-3 flex-shrink-0'
								style={{
									color: '#c58bf2',
								}}
							/>
							<p className='subscription-features-bullet-point '>
								Customised nutrition plan with Prapti
							</p>
						</a>
						<a
							className='my-2 d-flex flex-row align-items-center'
							href='/packages'
							style={{
								cursor: 'pointer',
							}}
						>
							<BsFillCheckCircleFill
								className='text-lg mr-3 flex-shrink-0'
								style={{
									color: '#c58bf2',
								}}
							/>
							<p className='subscription-features-bullet-point '>
								Join Live Classes conducted by Prapti
							</p>
						</a>
					</Col>
					<Col className='p-2 d-flex flex-column justify-content-center subscription-plans-container'>
						<h2
							style={{
								fontSize: '1.5rem',
								fontWeight: 'bold',
							}}
						>
							Select Plan
						</h2>
						<div
							className='d-flex flex-column justify-content-center align-items-center subscription-plans-card-container'
							style={{
								maxWidth: '400px',
							}}
						>
							{products.map((product, index) => {
								return (
									<Card
										key={index}
										className='w-100 my-2 subscription-plan-card'
										style={{
											borderRadius: '10px',
											boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
											cursor: 'pointer',
											border:
												selectedPackage.name === product.name &&
												selectedPackage.priceId === product.priceId
													? '2px solid #c58bf2'
													: '2px solid #e7e7e7',
											overflow: 'hidden',
										}}
										onClick={() => {
											setSelectedPackage({
												name: product.name,
												priceId: product.priceId,
											});
										}}
									>
										<CardBody className='p-3'>
											<div className='d-flex flex-row justify-content-between align-items-center'>
												<div>
													<h3
														style={{
															fontWeight: 'bold',
															fontSize: '0.8rem',
															margin: '0px',
														}}
													>
														{product.name}
													</h3>
													<h2
														style={{
															fontWeight: 'bold',
															fontSize: '1.5rem',
															margin: '0px',
														}}
													>
														{!product.isFree
															? `£${product.price} /${product.interval}`
															: `${product.price} ${product.interval}`}
													</h2>
													{product.freeTrialMessage && (
														<h4
															style={{
																fontWeight: 'bold',
																margin: '0px',
																color: '#c58bf2',
															}}
														>
															{product.freeTrialMessage}
														</h4>
													)}
													<div>
														<span
															className='mr-2'
															style={{
																fontSize: '0.8rem',
															}}
														>
															{product.classes} videos
														</span>
														<span
															style={{
																color: '#c58bf2',
																fontWeight: 'bold',
																fontSize: '0.8rem',
																textTransform: 'uppercase',
															}}
														>
															/{product.interval}
														</span>
													</div>
												</div>
												<div>
													{selectedPackage.name === product.name &&
													selectedPackage.priceId === product.priceId ? (
														<BsCheckSquareFill
															className='text-lg'
															style={{
																color: '#c58bf2',
															}}
														/>
													) : (
														<BsSquare
															className='text-lg'
															style={{
																color: '#e7e7e7',
															}}
														/>
													)}
												</div>
											</div>
											{selectedPackage.name === product.name &&
												selectedPackage.priceId === product.priceId && (
													<div className='pt-3'>
														<h3
															style={{
																fontWeight: 'bold',
																fontSize: '0.8rem',
															}}
														>
															Included in your subscription
														</h3>
														<hr className='my-1' />
														{product.features.map((feature, index) => (
															<div className='d-flex flex-row'>
																<BsFillCheckCircleFill
																	className='text-sm mr-2 mt-1 flex-shrink-0'
																	style={{
																		color: '#c58bf2',
																	}}
																/>
																<p className='subscription-features-bullet-point text-sm'>
																	{feature.text}
																</p>
															</div>
														))}
													</div>
												)}
										</CardBody>
										{selectedPackage.name === product.name &&
											selectedPackage.priceId === product.priceId && (
												<CardFooter
													className='d-flex p-1 flex-row justify-content-center align-items-center'
													style={{
														backgroundColor: '#e8e8fd',
														border: 'none',
													}}
												>
													<h3
														style={{
															margin: '0px',
															fontWeight: 'bold',
															fontSize: '1rem',
														}}
													>{`${product.discount}% OFF`}</h3>
												</CardFooter>
											)}
									</Card>
								);
							})}
							<label
								className='mt-3'
								htmlFor='coupon-input'
								style={{
									fontWeight: 'bold',
									fontSize: '1rem',
									alignSelf: 'flex-start',
								}}
							>
								Have a coupon?
							</label>
							<Input
								id='coupon-input'
								className=''
								placeholder='Enter Coupon'
								value={coupon}
								onChange={(e) => {
									setCoupon(e.target.value?.toUpperCase());
								}}
							/>
							<button
								className='btn btn-primary mt-3'
								style={{
									border: 'none',
									width: '80%',
									maxWidth: '300px',
									background:
										'linear-gradient(92.99deg, #EEA4CE -20.95%, #C58BF2 52.48%)',
									borderRadius: '60px',
									boxShadow: '0px 5.77364px 9.62274px rgba(128, 128, 128, 0.2)',
								}}
								onClick={() => {
									if (selectedPackage?.priceId) {
										if (stripeAddress) {
											createStripeSubscription();
										} else {
											setModalVisible(true);
										}
									} else {
										toast.error('Please select a plan');
									}
								}}
								disabled={!selectedPackage.priceId}
							>
								Subscribe Now
							</button>
						</div>
					</Col>
				</Row>
			</div>
			{modalVisible && (
				<Modal
					isOpen={modalVisible}
					toggle={() => {
						setModalVisible(false);
						setElementType(0);
						setClientSecret('');
					}}
					style={{
						maxWidth: '450px',
					}}
				>
					<ModalHeader
						toggle={() => {
							setModalVisible(false);
							setElementType(0);
							setClientSecret('');
						}}
					>
						Subscribe
					</ModalHeader>
					<ModalBody>
						{loading ? (
							<div className='w-100 h-100 d-flex justify-content-center align-items-center'>
								<Loader />
							</div>
						) : (
							<div>
								{elementType === 0 && (
									<Elements stripe={stripe}>
										<AddressForm onSubmit={createStripeUser} />
									</Elements>
								)}
								{elementType === 1 && (
									<Elements
										stripe={stripe}
										options={{
											clientSecret: clientSecret,
										}}
									>
										<CheckoutForm amount={amount} />
									</Elements>
								)}
							</div>
						)}
					</ModalBody>
				</Modal>
			)}
		</div>
	);
}
